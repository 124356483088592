










































































import Vue from 'vue'
import { combineLatest } from 'rxjs'
import { map, pluck, filter } from 'rxjs/operators'

import ItemList from './ItemList.vue'
import VersionSelect from './VersionSelect.vue'
import ToolbarMenu from './ToolbarMenu.vue'

export default Vue.extend({
  components: {
    "item-list": ItemList,
    "version-select": VersionSelect,
    "toolbar-menu": ToolbarMenu,
  },
  methods: {
    onPtrClick: function() {
      const realm = this.realm$ === 'ptr' ? 'live' : 'ptr'
      
      this.$router.push({
        name: 'version',
        params: {
          realm,
          version: this.allVersions$[realm][0].buildNumber,
          item: this.itemType$,
          itemId: this.selectedItem$.id,
          profile: this.profile$,
        }
      })
    },
  },
  subscriptions: function() {
    const selectedItem$ = this.$store.pipe(pluck('selectedItem'), filter(item => item !== null))
    const itemType$ = this.$store.pipe(pluck('params', 'itemType'))
    const versionDetails$ = this.$store.pipe(
      pluck('versionDetails'),
      filter(details => !!details),
    )

    return {
      itemType$,
      selectedItem$,
      realm$: this.$store.pipe(pluck('params', 'realm')),
      profile$: this.$store.pipe(pluck('params', 'profile')),
      selectedVersion$: this.$store.pipe(pluck('selectedVersion'), filter(version => version !== null)),
      selectedHero$: combineLatest(itemType$, selectedItem$).pipe(map(([ itemType, item ]) => itemType === 'heroes' ? item : {})),
      selectedMount$: combineLatest(itemType$, selectedItem$).pipe(map(([ itemType, item ]) => itemType === 'mounts' ? item : {})),
      heroes$: versionDetails$.pipe(pluck('heroes')),
      mounts$: versionDetails$.pipe(pluck('mounts')),
      versions$: this.$store.pipe(pluck('versions')),
      allVersions$: this.$store.pipe(pluck('allVersions')),
    }
  },
})
