





























import Vue from 'vue'
import Spinner from 'vue-simple-spinner'

export default Vue.extend({
  props: {
    show: Boolean,
    spinner: Boolean
  },
  components: {
    'vue-simple-spinner': Spinner,
  }
})
