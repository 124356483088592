












import Vue from 'vue'
import { pluck, filter } from 'rxjs/operators'

import JsonContent from './JsonContent.vue'
import ItemToolbar from './ItemToolbar.vue'

export default Vue.extend({
  components: {
    'json-content': JsonContent,
    'item-toolbar': ItemToolbar,
  },
  subscriptions: function() {
    return {
      selectedItem$: this.$store.pipe(pluck('selectedItem'), filter(item => item !== null)),
    }
  }
})
