









import Vue from 'vue'

import JsonPropertyKey from './JsonPropertyKey.vue'
import JsonPropertyValue from './JsonPropertyValue.vue'

export default Vue.extend({
  name: "json-boolean",
  components: {
    "json-property-key": JsonPropertyKey,
    "json-property-value": JsonPropertyValue,
  },
  props: {
    propertyKey: String,
    propertyValue: Boolean,
  },
  methods: {
    valueClasses: function() {
      const value = this.propertyValue
      return {
        'green--text': value,
        'red--text': !value,
        'text--darken-3': true,
      }
    },
  },
})
