
















import Vue from 'vue'

import Item from './Item.vue'

export default Vue.extend({
  components: {
    "item-component": Item
  },
  props: [ 'items', 'itemType', 'version', "profile" ],
  methods: {
    routerTo: function(item: any) {
      return {
        name: 'version',
        params: {
          realm: this.version.realm,
          version: this.version.buildNumber,
          item: this.itemType,
          itemId: item.id ? item.id.toLowerCase() : undefined,
          profile: this.profile
        },
      }
    },
  }
})
