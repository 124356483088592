























import Vue from 'vue'
import { format } from 'date-fns'

import { VERSION_DATE_FORMAT } from '../data/versions'
import VersionList from './VersionList.vue'
import VersionItem from './VersionItem.vue'

export default Vue.extend({
  components: {
    'version-item': VersionItem,
    'version-list': VersionList,
  },
  props: {
    selected: Object,
    versions: Array,
    itemType: String,
    itemId: String,
    profile: String,
  },
  computed: {
    selectedVersionDate: function() {
      return format(this.selected.releaseDate, VERSION_DATE_FORMAT)
    },
  },
})
