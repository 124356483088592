





















import Vue from 'vue'

import VersionItem from './VersionItem.vue'

export default Vue.extend({
  name: 'version-list',
  components: {
    'version-item': VersionItem,
  },
  props: {
    selected: Object,
    versions: Array,
    itemType: String,
    itemId: String,
    profile: String,
  },
})
