














import Vue from 'vue'

import JsonPropertyKey from './JsonPropertyKey.vue'
import JsonPropertyValue from './JsonPropertyValue.vue'

export default Vue.extend({
  name: "json-html",
  components: {
    "json-property-key": JsonPropertyKey,
    "json-property-value": JsonPropertyValue,
  },
  props: {
    propertyKey: String,
    propertyValue: String,
  },
})
