






import Vue from 'vue'
import { pluck } from 'rxjs/operators'

import JsonProperty from './JsonProperty.vue'

export default Vue.extend({
  components: {
    "json-property": JsonProperty
  },
  subscriptions: function() {
    const store$ = this.$store
    return {
      json$: store$.pipe(pluck('itemJSON')),
    }
  }
})
