














































































import Vue from 'vue'
import { format } from 'date-fns'

import { VERSION_DATE_FORMAT } from '../data/versions'
import ItemList from './ItemList.vue'
import VersionList from './VersionList.vue'

export default Vue.extend({
  components: {
    "item-list": ItemList,
    "version-list": VersionList,
  },
  props: {
    realm: String,
    profile: String,
    itemType: String,
    itemId: String,
    selectedVersion: Object,
    versions: Array,
    heroes: Array,
    mounts: Array,
  },
  data: function() {
    return {
      isVersionMenuOpen: false,
      isHeroMenuOpen: false,
      isMountMenuOpen: false,
    }
  },
  computed: {
    selectedVersionDate: function() {
      return format(this.selectedVersion.releaseDate, VERSION_DATE_FORMAT)
    },
  },
  methods: {
    toggleVersionMenu: function() {
      this.isVersionMenuOpen = !this.isVersionMenuOpen
    },
    toggleHeroMenu: function() {
      this.isHeroMenuOpen = !this.isHeroMenuOpen
    },
    toggleMountMenu: function() {
      this.isMountMenuOpen = !this.isMountMenuOpen
    },
  },
})
