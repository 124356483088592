















import Vue from "vue"
import { format } from 'date-fns'

import { VERSION_DATE_FORMAT } from '../data/versions'

export default Vue.extend({
  props: {
    version: Object,
    active: Boolean,
    itemType: String,
    itemId: String,
    profile: String,
  },
  computed: {
    versionDate: function() {
      return format(this.version.releaseDate, VERSION_DATE_FORMAT)
    },
    titleClasses: function() {
      return {
        "purple--text": this.active,
        "text--darken-3": this.active,
      }
    },
  },
  methods: {
    onSelect: function() {
      this.$router.push({
        name: 'version',
        params: {
          realm: this.version.realm,
          version: this.version.buildNumber,
          item: this.itemType,
          itemId: this.itemId,
          profile: this.profile,
        }
      })
    }
  },
})
