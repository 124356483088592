












































import Vue from 'vue'

export default Vue.extend({
  name: "json-array",
  components: {
    "json-property": () => import("./JsonProperty.vue")
  },
  props: {
    propertyKey: String,
    propertyValue: Array,
  },
  data: function() {
    return {
      expand: this.propertyValue && this.propertyValue.length === 1 ? true : false,
    }
  },
  methods: {
    onClick: function() {
      this.expand = !this.expand
    },
  },
})
