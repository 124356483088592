









import Vue from 'vue'
import { pluck, filter } from 'rxjs/operators'

import DownloadJSONBtn from './DownloadJSONBtn.vue'
import DownloadSourceBtn from './DownloadSourceBtn.vue'
import ProfileSelector from './ProfileSelector.vue'


export default Vue.extend({
  name: "item-toolbar",
  components: {
    "profile-selector": ProfileSelector
    "download-json-btn": DownloadJSONBtn
    "download-source-btn": DownloadSourceBtn
  },
})
