




















import Vue from 'vue'

import { buildAssetUrl } from '../data/base'

export default Vue.extend({
  props: [ 'item', 'versionNumber', 'to' ],
  computed: {
    hasImage: function() {
      return !!this.item.icon
    },
    imageUrl: function() {
      return buildAssetUrl(this.item.icon, this.versionNumber)
    }
  },
})
