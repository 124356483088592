


































import Vue from 'vue'

import JsonPropertyKey from './JsonPropertyKey.vue'

export default Vue.extend({
  name: "json-object",
  components: {
    "json-property": () => import("./JsonProperty.vue"),
    "json-property-key": JsonPropertyKey,
  },
  props: {
    propertyKey: String,
    propertyValue: Object,
  },
  data: function() {
    return {
      expand: this.propertyValue && Object.keys(this.propertyValue).length === 1 ? true : false,
    }
  },
  computed: {
    computedKey: function() {
      if(this.propertyKey) {
        return this.propertyKey
      }

      const key = this.propertyValue.name || this.propertyValue.id
      if(key) {
        return key
      }

      if(this.propertyValue.ability) {
        const ability = this.propertyValue.ability
        return ability.name || ability.id
      }

      if(this.propertyValue.button) {
        const button = this.propertyValue.button
        return button.name || button.id
      }

      return undefined
    },
  },
  methods: {
    onClick: function() {
      this.expand = !this.expand
    },
  },
})
